import React from "react";
import { Order } from "../helpers/api";
import { isEmpty } from "lodash";
import { v1 as uuidv1 } from "uuid";
import { sendNotification } from "../helpers/notification";
import moment from "moment";


export const OrderContext = React.createContext({});

export class OrderProvider extends React.Component {
  state = {
    createOrder: async () => {
      let params = {};
      params.amount = this.state.amount;
      params.card = this.state.card;
      params.bank = 'visa/master';
      params.receiver_bank = this.state.receiver_bank;
      params.receiver_name = this.state.receiver_name;
      params.obmenka_rate = this.state.obmenka_rate;
      params.external_id = this.state.external_id;

      await Order.createOrder(params)
        .then((res) => {
          sendNotification("success", "Удачно", "Заявка созданна");
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification(
            "warning",
            "Ошибка",
            `${error.response.body.message}`
          );
        });
    },
    getOrders: async (filter) => {
      await this.state.clear();
      await Order.getOrders(filter)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/auth/login",
            redirectMessage: "Недостаточно прав ⚠️",
            redirect: true,
          });
        });
    },
    getSumm: async () => {
      let params = {};
      if (!isEmpty(this.state.commentsumm)) params.comment = this.state.commentsumm;
      if (!isEmpty(this.state.commentid)) params.commentid = this.state.commentid;
      await Order.getSumm(params.comment, params.commentid)
        .then((res) => {
          this.setState({ calculatedCommentSumm: res.body.summ, errorText: null });
        })
        .catch((error) => {
          let message = JSON.parse(error.response.text).message
          this.setState({ errorText: message, calculatedCommentSumm: null });
        });
    },
    sendCallback: async () => {
      let params = {};
      if (!isEmpty(this.state.callbackUrl)) params.url = this.state.callbackUrl;
      if (!isEmpty(this.state.callbackOrderId)) {
        params.id = this.state.callbackOrderId;
      } else {
        sendNotification("danger", "Ошибка", "Поле не может быть пустое.");
        return
      }
      await Order.sendCallback(params.url, params.id)
        .then((res) => {
          sendNotification("success", "Удачно", res.body.operation_status);
        })
        .catch((error) => {
          sendNotification("danger", "Ошибка", JSON.parse(error.response.text).message);
        });
    },
    getExport: async () => {
      let params = {};
      if (!isEmpty(this.state.exportid)) params.exportid = this.state.exportid;
      await Order.getExport(params.exportid)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    getExportByDate: async () => {
      let params = {};
      if (!isEmpty(this.state.commentToSearch)) params.comment = this.state.commentToSearch;
      if (!isEmpty(this.state.dateToSearch.toString())) params.date = this.state.dateToSearch.toString();
      await Order.getExportByDate(params)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    getUserOrders: async () => {
      let params = {};
      if (this.state.filter.date) {
        params.date = moment(this.state.filter.date).format('YYYY-MM-DD');
      }
      if (!isEmpty(this.state.filter.status)) params.status = this.state.filter.status;
      await Order.getUserOrders(params).then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/auth/login",
            redirectMessage: "Недостаточно прав ⚠️",
            redirect: true,
          });
        });
    },
    setOrderToEdit: async (id) => {
      this.setState({ orderToEdit: id });
    },
    
    updateOrder: async () => {
      let params = {};
      if (!isEmpty(this.state.status)) params.status = this.state.status;
      if (!isEmpty(this.state.provider)) params.provider = this.state.provider;
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      if (!isEmpty(this.state.comment2)) params.comment2 = this.state.comment2;
      return Order.update(params, this.state.orderToEdit);
    },

    changeStatus: async (id) => {
      let params = {};
      if (!isEmpty(id)) params.status = "ERROR";
      return Order.changeStatus(params, id);
    },

    updateState: async (key, val) => {
      await this.setState((state) => {
        state[key] = val;
        return state;
      })
    },

    updateObjectProp: async (key, val) => {
      await this.setState({ filter: {...this.state.filter, [key]: val}});
    },

    clear: () => {
      this.setState({
        orders: [],
        redirect: false,
        redirectPath: "",
        redirectMessage: "",
        commentToSearch: null,
        commentsumm: null,
        commentid: null,
        callbackUrl: null,
        callbackOrderId: null,
        calculatedCommentSumm: null,
        exportid: null,
        dateToSearch: null,
        errorText: null,
        orderArray: [],
        filter:{},
      });
    },
    orderToEdit: "",
    orders: [],
    orderArray: [],
    redirectPath: "",
    redirectMessage: "",
    redirect: false,
    status: null,
    provider: "",
    comment: null,
    comment2: null,
    commentsumm: null,
    commentToSearch: null,
    callbackUrl: null,
    callbackOrderId: null,
    dateToSearch: '',
    commentid: null,
    calculatedCommentSumm: null,
    exportid: null,
    errorText: null,
    filter:{},
  };

  render() {
    return (
      <OrderContext.Provider value={this.state}>
        {this.props.children}
      </OrderContext.Provider>
    );
  }
}
